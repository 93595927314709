<template>
  <div class="basic">
    <pro-layout
      :menus="menus"
      :collapsed="collapsed"
      :mediaQuery="query"
      :isMobile="isMobile"
      :handleMediaQuery="handleMediaQuery"
      :handleCollapse="handleCollapse"
      :i18nRender="i18nRender"
      :logo="switchIcon"
      v-bind="settings"
    >
      <!-- openKeys={true} -->
      <tags-view v-if="needTagsView" />
      <!-- Ads begin
      广告代码 真实项目中请移除
      production remove this Ads
   

      <ads v-if="isProPreviewSite && !collapsed" />

     Ads end -->

      <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
      <template v-slot:menuHeaderRender>
        <div class="header">
          <img src="@/assets/wLogo.png" style="margin-left: 20px" />
          <h1>{{ title }}</h1>
        </div>
      </template>
      <template v-slot:logo> <img src="@/assets/wLogo.png" style="margin-left: 20px" /> </template>
      <!-- 1.0.0+ 版本 pro-layout 提供 API,
          增加 Header 左侧内容区自定义
    -->
      <template v-slot:headerContentRender>
        <div v-if="isMobile">
          <div style="padding-left: 0; color: #ffffff; display: flex; align-items: center; height: 45px">
            <img src="/logo.png" style="margin-left: 20px" height="40" />
            <h1 style="height: 45px; line-height: 45px; margin-bottom: 0; color: #fff">{{ title }}</h1>
          </div>
          <!-- <a-tooltip title="刷新页面">
          <a-icon type="reload" style="font-size: 18px;cursor: pointer;" @click="() => { $message.info('只是一个DEMO') }" />
        </a-tooltip> -->
          <!-- <img src="/logo.png" />
        <h1>{{ title }}</h1> -->
        </div>
      </template>
      <!-- <template v-slot:breadcrumbRender>
      <page-header-wrapper :title="false"></page-header-wrapper>
    </template> -->
      <setting-drawer v-if="isDev" :settings="settings" @change="handleSettingChange">
        <div style="margin: 12px 0">This is SettingDrawer custom footer content.</div>
      </setting-drawer>
      <template v-slot:rightContentRender>
        <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
      </template>

      <!-- custom footer / 自定义Footer -->
      <template v-slot:footerRender>
        <global-footer />
      </template>
      <router-view />
    </pro-layout>
  </div>
</template>

<script>
import { SettingDrawer, updateTheme } from '@ant-design-vue/pro-layout'
import TagsView from './TagsView/index.vue'
import switchIcon from '@/assets/switchIcon.png'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'
import { getTenantUsingGET } from '@/api/api/quanxianrenzhengguanli.ts'
import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'
import Ads from '@/components/Other/CarbonAds'
import { getTenantIdByDomain } from '@/api/login'

export default {
  name: 'BasicLayout',
  components: {
    SettingDrawer,
    RightContent,
    GlobalFooter,
    Ads,
    TagsView,
  },
  data() {
    return {
      switchIcon,
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      // end
      isDev: process.env.NODE_ENV === 'development' || process.env.VUE_APP_PREVIEW === 'true',

      // base
      menus: [],
      // 侧栏收起状态

      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false,
        // collapse: false,
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
      tenantId: undefined,
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
      needTagsView: (state) => state.settings.tagsView,
    }),
  },
  created() {
    const routes = this.mainMenu.find((item) => item.path === '/')
    // if (window.location.host.split('.')?.[0]) {
    //   const octalRegex = /^(0o)?[0-7]+$/i
    //   const tenantIdStr = window.location.host.split('.')?.[0]
    //   const tenantId = octalRegex.test(tenantIdStr) ? tenantIdStr : 1
    //   getTenantUsingGET({ id: tenantId })
    //     .then((res) => {
    //       if (res.code == 200) {
    //         this.title = `比目鱼 - ${res.data.tenantName}`
    //       }
    //     })
    //     .catch((error) => {
    //       this.$message.error(((error.response || {}).data || {}).msg || '请求出现错误')
    //     })
    // }
    getTenantIdByDomain(window.location.host)
      .then((res) => {
        // if (this.tenantId) {
        //   getTenantUsingGET({ id: this.tenantId })
        //     .then((res) => {
        //       if (res.code == 200) {
        //         this.timeStart()
        //         this.info = res.data
        //         this.time = formatDate(new Date(res.data.expire))
        //       }
        //     })
        //     .catch((error) => {
        //       this.$message.error(((error.response || {}).data || {}).msg || '请求出现错误')
        //     })
        // }

        this.info = res.data
      })
      .catch((error) => {
        this.$message.error(((error.response || {}).data || {}).msg || '请求出现错误')
      })
    this.menus = (routes && routes.children) || []
    // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.collapsed = true
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.collapsed, this.isMobile)
    })
  },
  watch: {
    $route(to, from) {
      if (this.isMobile) {
        this.collapsed = true
      }
    },
  },
  mounted() {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }

    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      updateTheme(this.settings.primaryColor)
    }
  },
  methods: {
    i18nRender,
    handleMediaQuery(val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse(val) {
      this.collapsed = val
    },
    handleSettingChange({ type, value }) {
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    },
  },
}
</script>

<style lang="less">
@import './BasicLayout.less';
.ant-pro-global-header {
  min-width: 375px !important;
}
.ant-pro-sider-menu-logo {
  height: 65px;
  padding-left: 0 !important;
  line-height: 65px;
  -webkit-box-shadow: none !important;
}
.header {
  background: rgba(30, 41, 59, 1);
  padding-left: 0;
  color: #ffffff;
}
.ant-pro-sider-menu-sider.light .ant-pro-sider-menu-logo h1 {
  color: #fff !important;
}
.ant-pro-global-header {
  height: 45px;
  padding-left: 0;
  color: #ffffff;
  line-height: 45px;
  background: rgba(255, 255, 255, 1);
}
.ant-layout-header {
  height: 45px;
  line-height: 45px;
}
.ant-pro-global-header-trigger {
  height: 45px;
  line-height: 45px;
}
.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-root {
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
}
.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-root::-webkit-scrollbar {
  display: none;
}
.ant-pro-global-header-content {
  height: 45px;
}
.ant-pro-page-header-wrap-page-header-warp {
  position: fixed;
  top: 45px;
  z-index: 3;
  width: 100%;
  background-color: #fff;
}
.ant-pro-page-header-wrap-children-content {
  margin: 100px 24px 24px 24px;
}
@media screen and (max-width: 750px) {
  .ant-layout-content {
    margin-top: 70px;
  }
  /* 在此处编写对应的样式 */
  .ant-pro-global-header-logo {
    display: none;
    height: 45px;
  }
  .ant-pro-global-header-index-right {
    margin-right: 0;
  }
  .ant-pro-global-header-index-right {
    float: none;
  }
}
</style>
