<template>
  <a-dropdown v-if="currentUser && currentUser.name">
    <!-- <span class="ant-pro-account-avatar" style="background: #579ec8 !important">
      <a-avatar size="small" src="/avatar2.png" class="antd-pro-global-header-index-avatar" />
      <span>{{ currentUser.name }}({{ currentUser.empNo ?? 1 }})</span>
    </span> -->
    <div class="info">
      <div class="avatar header-icon" style="background: rgba(51, 65, 85, 1)">
        <img src="@/assets/wAvatar.png" alt="" />
      </div>
      <span>{{ currentUser.name }}({{ currentUser.empNo ?? 1 }})</span>
      <img src="@/assets/arrow.png" alt="" class="arrow" />
    </div>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <a-icon type="user" />
          个人信息
        </a-menu-item>
        <!-- <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <a-icon type="setting" />
          {{ $t('menu.account.settings') }}
        </a-menu-item> -->
        <a-menu-divider v-if="menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <a-icon type="logout" />
          {{ $t('menu.account.logout') }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8 }" />
  </span>
</template>

<script>
import { Modal } from 'ant-design-vue'

export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleToCenter() {
      this.$router.push({ path: '/my/info' })
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout(e) {
      Modal.confirm({
        title: this.$t('layouts.usermenu.dialog.title'),
        content: this.$t('layouts.usermenu.dialog.content'),
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))

          return this.$store.dispatch('Logout').then(() => {
            this.$store.dispatch('tagsView/delAllViews')
            this.$router.push({ name: 'login' })
          })
        },
        onCancel() {},
      })
    },
  },
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  :deep(.action) {
    margin-right: 8px;
  }
  :deep(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
.ant-pro-global-header-index-right .ant-pro-account-avatar .antd-pro-global-header-index-avatar {
  margin-top: 12px !important;
}
.info {
  display: flex;
  // align-items: center;
  color: rgba(71, 85, 105, 1);
  font-size: 14px;
  font-weight: 500;
  .arrow {
    width: 10.8px;
    height: 6.8px;
    margin: 20px 0 0 20px;
  }
}
</style>
