import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { login, getInfo, logout } from '@/api/login'
import { updateWechatCallback } from '@/api/wxLogin'

import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

storage.addPlugin(expirePlugin)
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    id: '',
    empNo: '',
    roles: [],
    info: {},
    per: [],
    tenantName: '',
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome, id, empNo }) => {
      state.name = name
      state.id = id
      state.empNo = empNo
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_PER: (state, per) => {
      state.per = per
    },
    SET_TENANTNAME: (state, tenantName) => {
      state.tenantName = tenantName
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            const result = response
            storage.set(ACCESS_TOKEN, result.data.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.data.token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 扫码登录
    UpdateWechatCallback({ commit }, query) {
      return new Promise((resolve, reject) => {
        updateWechatCallback(query.code, query.state)
          .then((response) => {
            const result = response
            storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        // 请求后端获取用户信息 /api/user/info
        getInfo()
          .then((response) => {
            console.log(response, 1111111111)
            commit('SET_NAME', {
              name: response.data.userName,
              id: response.data.id,
              empNo: response.data.empNo,
              welcome: welcome(),
            })

            commit('SET_INFO', response.data)
            commit('SET_PER', response.data.pers)

            commit('SET_ROLES', { permissions: [], permissionList: [] })
            resolve(response)
            // if (result.role && result.role.permissions.length > 0) {
            //   const role = { ...result.role }
            //   role.permissions = result.role.permissions.map(permission => {
            //     const per = {
            //       ...permission,
            //       actionList: (permission.actionEntitySet || {}).map(item => item.action)
            //      }
            //     return per
            //   })
            //   role.permissionList = role.permissions.map(permission => { return permission.permissionId })
            //   // 覆盖响应体的 role, 供下游使用
            //   result.role = role

            //   // 下游
            //   resolve(result)
            // } else {
            //   reject(new Error('getInfo: roles must be a non-null array !'))
            // }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_PER', [])

            storage.remove(ACCESS_TOKEN)
            resolve()
          })
          .catch((err) => {
            console.log('logout fail:', err)
            // resolve()
          })
          .finally(() => {})
      })
    },
  },
}

export default user
